import boxes from "./components/boxes"
import buttons from "./components/buttons"
import loader from "./components/loader"
import text from "./components/text"
import theme from "./themes/default"

const global = {
  "@global": {
    "*": {
      fontFamily: "'Nunito', sans-serif",
    },

    body: {
      backgroundColor: "#FFFEFC",
      background: "linear-gradient(to right, #EFF0F3 0%, #EFF0F3 100%)",
    },

    button: {
      "&:focus": {
        outline: "none",
      },
    },

    // SCROLLBAR
    /* width */
    "::-webkit-scrollbar": {
      width: "8px",
      left: "100px",
    },
    /* Track */
    "::-webkit-scrollbar-track": {
      boxShadow: "inset 1px 1px 1px #a6abbd, inset -1px -1px 1px #fafbff",
      borderRadius: "3px",
      border: "1px solid rgba(255, 255, 255, 0.4)",
      backgroundColor: "#ebecf0",
      backgroundImage:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(0, 0, 0, 0.04) 100%)",
    },
    /* Handle */
    "::-webkit-scrollbar-thumb": {
      margin: "3px",
      borderRadius: "3px",
      border: "1px solid rgba(255, 255, 255, 0.8)",
      backgroundColor: "#777b91",
    },
    /* Handle on hover */
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },

  breakpointBelowXS: {
    [`@media (min-width: ${theme.breakpoints.XS})`]: {
      display: "none !important",
    },
  },
  breakpointOverXS: {
    [`@media (max-width: ${theme.breakpoints.XS})`]: {
      display: "none !important",
    },
  },

  breakpointBelowS: {
    [`@media (min-width: ${theme.breakpoints.S})`]: {
      display: "none !important",
    },
  },
  breakpointOverS: {
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      display: "none !important",
    },
  },

  breakpointBelowM: {
    [`@media (min-width: ${theme.breakpoints.M})`]: {
      display: "none !important",
    },
  },
  breakpointOverM: {
    [`@media (max-width: ${theme.breakpoints.M})`]: {
      display: "none !important",
    },
  },

  breakpointBelowL: {
    [`@media (min-width: ${theme.breakpoints.L})`]: {
      display: "none !important",
    },
  },
  breakpointOverL: {
    [`@media (max-width: ${theme.breakpoints.L})`]: {
      display: "none !important",
    },
  },
}

export default {
  ...loader,
  ...buttons,
  ...text,
  ...boxes,
  ...global,
}
