export default {
  btnPrimary: {
    flexShrink: 0,
    maxHeight: "50px",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "700",
    letterSpacing: "1px",
    textTransform: "uppercase",
    textAlign: "center",

    padding: "12px 22px",
    background: "#3ccd58",
    border: "none",
    borderRadius: "10px",
    boxShadow:
      "2px 2px 5px rgba(80, 178, 125, 0.51), -2px -2px 4px rgba(255, 255, 255, 0.9)",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& i:before": {
      color: "#ffffff",
      fontSize: "11px",
    },
    "&-left i:before": {
      marginRight: "10px",
    },
    "&-right i:before": {
      marginLeft: "10px",
    },

    "&:hover": {
      color: "#ffffff",
      textDecoration: "none",
      backgroundColor: "#35BE4F",
      /* TODO: background: "linear-gradient(317.7deg, #000000 0%, #FFFFFF 100%)", */
      boxShadow: "inset -2px -2px 3px 0 #7AD48B, inset 2px 2px 3px 0 #50B27D",
    },

    "&:focus": {
      outline: "none",
    },
  },

  btnSecondary: {
    color: "#565a6f",
    fontSize: "14px",
    fontWeight: "700",
    letterSpacing: "1.08px",
    textTransform: "uppercase",
    padding: "12px 22px",
    border: "none",
    borderRadius: "10px",
    backgroundRepeat: "no-repeat",
    boxShadow: "2px 2px 3px #a6abbd, -2px -2px 3px #ffffff",
    backgroundSize: "60% 60%",
    backgroundPosition: "center center",
    backgroundColor: "#EBECF0",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      color: "#565a6f",
      textDecoration: "none",
      backgroundColor: "#E2E3E7",
      boxShadow: "inset -2px -2px 3px 0 #FAFBFF, inset 2px 2px 3px 0 #A6ABBD",
      cursor: "pointer",
    },

    "&:focus": {
      outline: "none",
    },

    "& i:before": {
      color: "#565a6f",
      fontSize: "11px",
    },
    "&-left i:before": {
      marginRight: "10px",
    },
    "&-right i:before": {
      marginLeft: "10px",
    },

    "&--small": {
      padding: "6px 8px",
      color: "#565a6f",
      fontSize: "12px",
      fontWeight: "700",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
      borderRadius: "6px",
    },
  },

  btnSwitch: {
    width: "39px",
    height: "20px",
    boxShadow:
      "inset 2px 2px 2px rgba(166, 171, 189, 0.5), inset -2px -2px 3px #fafbff",
    borderRadius: "26px",
    border: "1px solid rgba(119, 123, 145, 0.11)",
    backgroundColor: "#fafafa",
    backgroundImage:
      "linear-gradient(-42deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%)",

    padding: "0px",

    "&:focus": {
      outline: "none",
    },

    "&>*": {
      position: "relative",
      left: "0px",
      width: "17px",
      height: "17px",
      boxShadow:
        "1px 1px 1px rgba(80, 178, 125, 0.51), -1px -1px 8px rgba(255, 255, 255, 0.9)",
      borderRadius: "15px",
      backgroundColor: "#565a6f",
      transition: "all .2s ease",
    },

    "&--on>*": {
      backgroundColor: "#3ccd58",
      left: "calc(100% - 17px)",
    },
  },

  btnWithIcon: {
    position: "relative",
    color: "#565a6f",
    fontSize: "12px",
    fontWeight: "700",
    letterSpacing: ".5px",
    textTransform: "uppercase",
    border: "none",
    background: "none",
    "&>img": {
      height: "22px",
      margin: "auto",
    },
    "&-left>img": {
      marginRight: "12px",
    },
    "&-right>img": {
      marginLeft: "12px",
    },

    "&>i:before": {
      fontSize: "22px",
      position: "relative",
      top: "6px",
    },
    "&-left>i:before": {
      marginRight: "12px",
    },
    "&-right>i:before": {
      marginLeft: "12px",
    },

    "&:hover": {
      textShadow: "1px 2px 3px rgba(0,0,0, .2)",
    },
  },

  textOnlyButton: {
    color: "#565a6f",
    border: "none",
    background: "none",
    outline: "none",

    "&:hover": {
      textShadow: "1px 2px 3px rgba(0,0,0, .2)",
    },
  },
}
