export function storageFactory(storage: Storage): Storage {
  let inMemoryStorage: { [key: string]: string } = {}
  const length = storage.length

  function isSupported() {
    try {
      const testKey = 'random_key'
      storage.setItem(testKey, testKey)
      storage.removeItem(testKey)
      return true
    } catch (e) {
      return false
    }
  }

  function clear(): void {
    if (isSupported()) {
      storage.clear()
    } else {
      inMemoryStorage = {}
    }
  }

  function getItem(name: string): string | null {
    if (isSupported()) {
      return storage.getItem(name)
    }
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name]
    }
    return null
  }

  function key(index: number): string | null {
    if (isSupported()) {
      return storage.key(index)
    } else {
      return Object.keys(inMemoryStorage)[index] || null
    }
  }

  function removeItem(name: string): void {
    if (isSupported()) {
      storage.removeItem(name)
    } else {
      delete inMemoryStorage[name]
    }
  }

  function setItem(name: string, value: string): void {
    if (isSupported()) {
      storage.setItem(name, value)
    } else {
      inMemoryStorage[name] = String(value)
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    length,
  }
}

// export const localStore = storageFactory(localStorage)
export const sessionStore =
  typeof window !== 'undefined' ? storageFactory(sessionStorage) : null
