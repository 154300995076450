import { InMemoryCache, gql } from "@apollo/client"
import {
  configuratorInitSubLineId,
  customerSetsVar,
  getActiveCustomerSet,
  isCustomerSetHintVisibleVar,
  pdfVisualizerLayersVar,
} from "./reactive-variables/configuratorVariables"

export const typeDefs = gql`
  extend type Query {
    isCustomerSetHintVisible: Boolean
    customerSets: Any
    activeCustomerSet: Any
  }
`

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isCustomerSetHintVisible: {
          read() {
            return isCustomerSetHintVisibleVar()
          },
        },
        configuratorInitSubLineId: {
          read() {
            return configuratorInitSubLineId()
          },
        },
        customerSets: {
          read() {
            return customerSetsVar()
          },
        },
        activeCustomerSet: {
          read() {
            return getActiveCustomerSet()
          },
        },
        pdfVisualizerLayers: {
          read() {
            return pdfVisualizerLayersVar()
          },
        },
      },
    },
  },
})
