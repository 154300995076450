/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import ReactDOM from 'react-dom'
import "./src/styles/global.js"

/*
  Gatsby and react-jss do not add styles to <head> for first SSR render (gatsby build)
  https://github.com/gatsbyjs/gatsby/issues/8560#issuecomment-535265414
**/
export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export { wrapRootElement } from "./gatsby-ssr"
