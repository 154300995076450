import DefaultTheme from "../themes/default"

export default {
  hintBox: {
    padding: "15px 14px",
    color: "white",
    fontSize: "12px",
    fontWeight: "300",
    boxShadow: "0 0 8px rgba(166, 171, 189, 0.6)",
    borderRadius: "4px",
    backgroundColor: DefaultTheme.colors.darkGrey,
    backgroundImage:
      "linear-gradient(-42deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%)",

    "&-arrow": {
      margin: "0px !important",
      padding: "0px !important",
      border: "6px solid rgba(0,0,0,0)",

      "&--top": {
        borderBottomColor: DefaultTheme.colors.darkGrey,
      },
      "&--bottom": {
        borderTopColor: DefaultTheme.colors.darkGrey,
      },
    },
  },
}
