import { BREAKPOINTS } from '../constants'

const breakPointsObject = () => {
  const object = {}

  for (const [key, value] of Object.entries(BREAKPOINTS)) {
    Object.assign(object, { [key]: `${value}px` })
  }

  return object as Breakpoints
}

const queriesObject = () => {
  const object = {}

  for (const [key, value] of Object.entries(BREAKPOINTS)) {
    Object.assign(object, { [key]: `@media(max-width: ${value}px)` })
  }

  return object as Queries
}

const colors = {
  primaryGreen: '#3CCD58',
  primaryGrey: '#777B91',
  darkGrey: '#565A6F',
  lightGrey: '#ADB2CD',
  tintedGrey: '#61657a',
  fadedGrey: '#c9ccd6',
  warning: '#CD583C',
  inputError: 'red',
}

export const breakpoints = breakPointsObject()
export const queries = queriesObject()

type Breakpoints = {
  M: string
  S: string
  L: string
  XS: string
  XL: string
}
type Queries = {
  M: string
  S: string
  L: string
  XS: string
  XL: string
}
type defaultThemeObject = {
  colors: typeof colors
  breakpoints: Breakpoints
  queries: Queries
}

const defaultTheme: defaultThemeObject = {
  colors,
  breakpoints,
  queries,
}

export default defaultTheme
