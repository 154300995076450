import cs from "./cs"
import i18next from "i18next"

export const namespaces = Object.entries(cs).map(entry => entry[0])

i18next.init({
  lng: "cs",
  resources: {
    cs,
  },
  ns: namespaces, // LIST OF NAMESPACES
  defaultNS: namespaces[0],
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
})

export default i18next
