/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import './src/styles/global.js'

import { ApolloProvider } from '@apollo/client'
import { I18nextProvider } from 'react-i18next'
import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
import { ThemeProvider } from 'react-jss'
import { client } from './src/apollo/client'
import i18next from 'locales/i18next'
import theme from 'styles/themes/default'

export const onRenderBody = ({ setHeadComponents }) => {
  if (process.env.NODE_ENV !== 'development') {
    setHeadComponents([
      <script
        data-cookieconsent="ignore"
        id={process.env.NODE_ENV}
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html:
            "window.dataLayer = window.dataLayer || [];window.dataLayer.push({'platform':'gatsby'}); (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl+'';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', 'GTM-59TRR4D');",
        }}
      />,
    ])
  }
}

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18next}>
        <SimpleReactLightbox>{element}</SimpleReactLightbox>
      </I18nextProvider>
    </ThemeProvider>
  </ApolloProvider>
)
