import theme from "../themes/default"

export default {
  loader: {
    border: "4px solid white",
    borderTop: `4px solid ${theme.colors.primaryGreen}`,
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "$spin 1s linear infinite",
    margin: "auto",
  },

  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },

  loadingText: {
    color: "#565a6f",
    fontSize: "12px",
    fontWeight: "700",
    letterSpacing: ".5px",
    textTransform: "uppercase",
    textAlign: "center",
  },
}
