import { sessionStore } from "utils/StorageUtils"
import { isString } from "utils/Validators"

/*
  Not optimal solution
  Apollo has open PR for persisting reactive variables
  /apollographql/apollo-client/pull/7148/
*/

const stringifyAny = (token: any) => {
  return isString(token) ? token : JSON.stringify(token)
}

const parseAny = (token: any) => {
  let json
  try {
    if (isString(token)) {
      json = JSON.parse(token)
    }
  } catch {
    json = undefined
  }

  return json
}

export const saveCustomerSetsVar = (token: any) => {
  sessionStore?.setItem("customerSetsVar", stringifyAny(token))
}
export const saveActiveCustomerSetIdVar = (token: any) => {
  sessionStore?.setItem("activeCustomerSetIdVar", stringifyAny(token))
}

export const storedCustomerSetsVar = () => {
  return parseAny(sessionStore?.getItem("customerSetsVar"))
}

export const storedActiveCustomerSetIdVar = () => {
  return sessionStore?.getItem("activeCustomerSetIdVar")
}
