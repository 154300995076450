import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import { cache, typeDefs } from "./cache"

import fetch from "cross-fetch"
export const ESKLADY_AUTH_TOKEN =
  process.env.GATSBY_ESKLADY_TOKEN || "pxlne3d5vr87qahcap84"
export const ESKLADY_API_URL =
  process.env.GATSBY_ESKLADY_URL || "https://www.esklady.cz/graphql"

export const client = new ApolloClient({
  link: new HttpLink({
    uri: `${
      process.env.API_URL ||
      process.env.GATSBY_API_URL ||
      "https://vypinac-production.herokuapp.com/graphql"
    }`,
    fetch,
  }),
  cache,
  typeDefs,
})

export const eskladyClient = new ApolloClient({
  link: new HttpLink({
    uri: ESKLADY_API_URL,
    fetch,
  }),
  cache: new InMemoryCache(),
})
