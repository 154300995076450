import theme from "../themes/default"

export default {
  "@global h2": {
    color: "#565a6f",
    textTransform: "uppercase",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.67px",
  },

  "@global h3": {
    color: "#565a6f",
    textTransform: "uppercase",
    fontSize: "24px",
    fontWeight: "700",
    letterSpacing: "1px",
  },

  "@global h4": {
    color: "#565a6f",
    fontSize: "20px",
    fontWeight: "700",
    letterSpacing: "1px",
  },

  [`@media (max-width: ${theme.breakpoints.M})`]: {
    "@global h3": {
      fontSize: "20px",
    },
  },
}
