export const CAT_DEVICE = "Mechanizmus"
export const CAT_FRAME = "Rámeček a kryt"

export const TYPE_COVER_FRAME = "Krycí rámeček"
export const TYPE_COVER_DEVICE_FRAME = "Kryt vypínače"
export const TYPE_DECOR_FRAME = "Dekorativní rámeček"
export const TYPE_ZAMAK_FRAME = "Montážní rámeček - Zamak"
export const TYPE_SLABO_PROUD_FRAME = "Slaboproudé zásuvky"

export const db = {
  CAT_DEVICE,
  CAT_FRAME,
  TYPE_COVER_FRAME,
  TYPE_COVER_DEVICE_FRAME,
  TYPE_DECOR_FRAME,
  TYPE_ZAMAK_FRAME,
  TYPE_SLABO_PROUD_FRAME
}

export const MERTEN = ['MTN3115-0000', 'MTN3111-0000']
