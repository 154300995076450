/**
 *
 * @param imageObject - image object from graphql
 * @param size - "large", "medium", "small" or "thumbnail"
 */

export type sizeType = "large" | "medium" | "small" | "thumbnail"

export const getImage = (
  imageObject: {
    url: any
    formats?: any
  },
  size: sizeType
) => {
  if (imageObject) {
    const { formats, url } = imageObject
    const sizes = ["large", "medium", "small", "thumbnail"]

    if (!formats || formats === null) return url

    const sizeIndex = sizes.findIndex(sizeString => sizeString === size)

    for (let i = sizeIndex; i < sizes.length; i++) {
      if (formats[sizes[i]]) {
        return formats[sizes[i]].url
      }
    }
  }
  return ""
}
