import { removeFromCartCompletely } from 'apollo/reactive-variables/configuratorVariables'
import { db } from 'constants/database'
import { getImage } from 'helpers/image'
import { PropNameSingular } from 'types/Common'

export const isPartOfSelectedColor = (part, selectedColor) => {
  return part.Properties.find((p) => p.Color?.id === selectedColor?.id)
}

export const mergeById = (a1, a2) => {
  return a1.map((itm) => ({
    ...a2.find((item) => item.id === itm.id && item),
    ...itm,
  }))
}

export const isHorizontalPart = (part) => {
  return part.Properties.find((p) => p.Orientation === 'H')
}

export const isVerticalPart = (part) => {
  return part.Properties.find((p) => p.Orientation === 'V')
}

export const isPopularPart = (part) => {
  return part?.SubType?.Popular
}

export const isPartInArray = (partArray, part) => {
  return partArray.find((p) => p.id === part.id)
}

export const isUniversalDevice = (devicePart) => {
  const deviceDoesNotHaveColor =
    devicePart?.Properties?.findIndex((property) => property.Color) === -1
  const deviceDoesHaveCoupledWithParts = devicePart?.CoupledWith?.length > 0
  return deviceDoesNotHaveColor && deviceDoesHaveCoupledWithParts
}

export const getPartSize = (part) => {
  return part?.Properties
    ? part?.Properties.find((property) => property.Size)?.Size?.Value
    : null
}

export const getPartSizeIcon = (part) => {
  return part?.Properties
    ? part?.Properties.find((property) => property.Size)?.Size?.Icon
    : null
}

export const getPartColor = (part) => {
  return part?.Properties
    ? part?.Properties.find((property) => property.Color)?.Color
    : null
}

export const getColorsFromParts = (PartsArray) => {
  if (!PartsArray) {
    return []
  }

  const colorArray = PartsArray.map((part) => {
    const colorPart = getPartColor(part)
    return colorPart
      ? {
          Color: colorPart,
          Price: part.Price,
          Part: part,
          ShowInVisualizer: part.ShowInVisualizer,
        }
      : null
  }).filter((part) => part?.ShowInVisualizer)

  const colorArrayUnique = []
  colorArray.forEach((obj) => {
    const index = colorArrayUnique.findIndex(
      (storedObj) => storedObj.id === obj.Color?.id,
    )
    if (index < 0) {
      colorArrayUnique.push({
        ...obj.Color,
        Price: obj.Price,
        Parts: [obj.Part],
      })
    } else {
      colorArrayUnique[index]?.Parts?.push(obj.Part)
    }
  })

  return colorArrayUnique
}

export const getPartPropName = (part): PropNameSingular => {
  let propName = null

  switch (part?.Category?.Name.toLowerCase()) {
    case db.CAT_DEVICE.toLowerCase():
      propName = 'device'
      break
    case db.CAT_FRAME.toLowerCase():
      if (
        part.Type?.Name.toLowerCase() ===
        db.TYPE_SLABO_PROUD_FRAME.toLowerCase()
      ) {
        propName = 'device'
      } else if (
        part.Type?.Name.toLowerCase() === db.TYPE_COVER_FRAME.toLowerCase()
      ) {
        propName = 'frame'
      } else if (
        part.Type?.Name.toLowerCase() ===
        db.TYPE_COVER_DEVICE_FRAME.toLowerCase()
      ) {
        propName = 'universal'
      } else if (
        part.Type?.Name.toLowerCase() === db.TYPE_DECOR_FRAME.toLowerCase()
      ) {
        propName = 'decorativeFrame'
      } else if (
        part.Type?.Name.toLowerCase() === db.TYPE_ZAMAK_FRAME.toLowerCase()
      ) {
        propName = 'constructionFrame'
      }
      break
    default:
    // nope
  }
  return propName
}

export const mapTableDevices = (deviceParts, selectedColor, productSubLine) => {
  const options = []
  deviceParts?.forEach((part) => {
    const hasUniversalDeviceSomeCoupledWithColor =
      isUniversalDevice(part) &&
      part.CoupledWith.some(
        (coupledPart) => getPartColor(coupledPart)?.Hex === selectedColor?.Hex,
      )
    if (
      getPartColor(part)?.Hex === selectedColor?.Hex ||
      hasUniversalDeviceSomeCoupledWithColor
    ) {
      const categoryId = part.Type?.id
      const categoryName = part.Type?.Name
      const categoryIcon = getImage(part.Type?.Icon, 'thumbnail')

      let tableMessage = ''
      if (getPartSize(part) === '0.5') {
        tableMessage = 'Velikost 1/2, do košíku vložte 2x'
      } else if (getPartSize(part) === '0') {
        tableMessage = 'Do košíku automaticky přidán speciální rámeček'
      }

      const normalizedPart = {
        ...part,
        tableFullName: part.Name,
        tableName: part.SubType?.Name,
        pdfName: part.Name,
        tableDescription: part.SubType?.Description,
        tableIcon: part.SubType?.Icon,
        tableIsDevice: true,
        tableMessage: tableMessage,
      }

      const optionIndex = options.findIndex(
        (option) => option.name === categoryName,
      )

      if (optionIndex < 0) {
        const labels = [
          { id: 'popular', name: 'Nejprodávanější', items: [] },
          { id: 'other', name: 'Další možnosti', items: [] },
          { id: 'inOtherLines', name: 'V dalších řadách', items: [] },
        ]

        let labelIndex = labels.findIndex((label) => label.id === 'other')
        if (isPopularPart(part)) {
          labelIndex = labels.findIndex((label) => label.id === 'popular')
        }
        labels[labelIndex].items.push(normalizedPart)

        options.push({
          id: categoryId,
          name: categoryName,
          thumbnail: categoryIcon,
          labels: labels,
        })
      } else {
        let labelIndex = options[optionIndex]?.labels.findIndex(
          (label) => label.id === 'other',
        )
        if (isPopularPart(part)) {
          labelIndex = options[optionIndex]?.labels.findIndex(
            (label) => label.id === 'popular',
          )
        }
        options[optionIndex]?.labels[labelIndex]?.items.push(normalizedPart)
      }
    }
  })

  // Change name of the 'other' label from 'Další Možnosti' to 'Možnosti', if needed
  //
  options.forEach((category) => {
    const filledLabels = category.labels.filter((label) => label.items.length)
      .length
    const labelIndex = category.labels.findIndex(
      (label) => label.id === 'other',
    )
    if (
      filledLabels <= 1 &&
      labelIndex >= 0 &&
      category.labels[labelIndex].items.length
    ) {
      category.labels[labelIndex].name = 'Možnosti'
    }
  })

  if (productSubLine.Name === 'Antique') {
    return options
      .filter((item) => item.name !== 'Kryt vypínače')
      .sort((a, b) => (a.id < b.id ? -1 : 1))
  }
  return options.sort((a, b) => (a.id < b.id ? -1 : 1))
}

export const mapTableFrames = (frameParts, selectedColor) => {
  const options = []
  const horizontalFrames = []
  const verticalFrames = []
  const universalFrames = []

  frameParts?.forEach((part) => {
    if (getPartColor(part)?.id === selectedColor?.id) {
      const normalizedPart = {
        ...part,
        tableFullName: part.Name,
        tableName: part.Name,
        pdfName: part?.Name,
        tableDescription: null,
        tableIcon: getPartSizeIcon(part),
        tableIconVertical: false,
        tableIsFrame: true,
      }
      if (isHorizontalPart(part) && isVerticalPart(part)) {
        universalFrames.push(normalizedPart)
      } else {
        if (isHorizontalPart(part)) {
          horizontalFrames.push(normalizedPart)
        }
        if (isVerticalPart(part)) {
          verticalFrames.push({ ...normalizedPart, tableIconVertical: true })
        }
      }
    }
  })

  horizontalFrames.sort((a, b) => (getPartSize(a) < getPartSize(b) ? -1 : 1))
  verticalFrames.sort((a, b) => (getPartSize(a) < getPartSize(b) ? -1 : 1))
  universalFrames.sort((a, b) => (getPartSize(a) < getPartSize(b) ? -1 : 1))

  options.push({
    id: 'universal',
    name: 'Univerzální',
    items: universalFrames,
  })
  options.push({
    id: 'horizontal',
    name: 'Horizontální',
    items: horizontalFrames,
  })
  options.push({
    id: 'vertical',
    name: 'Vertikální',
    items: verticalFrames,
  })

  return options
}

export const mapTableDecorativeFrames = (decorativeFrameParts) => {
  const options = []

  decorativeFrameParts?.forEach((part) => {
    const normalizedPart = {
      ...part,
      tableIcon: part?.Type?.Icon,
      tableColor: getPartColor(part),
    }
    options.push(normalizedPart)
  })

  options.sort((a, b) => (getPartSize(a) < getPartSize(b) ? -1 : 1))

  return options
}

export const getCoupledWithParts = (coupledWith, mappedParts) => {
  let allParts = []
  if (mappedParts) {
    Object.values(mappedParts).forEach((val) => {
      //@ts-ignore
      allParts.push(...val.parts)
    })
  }

  const coupledParts = coupledWith?.map((coupledInfo) =>
    allParts.find((part) => part.PartNumber === coupledInfo.PartNumber),
  )

  return coupledParts && coupledParts?.length ? coupledParts : []
}

export const getUniversalCoupledWithParts = (coupledWith, activeColor) => {
  const coupledParts = coupledWith.filter(
    (coupledPart) => getPartColor(coupledPart)?.Hex === activeColor.Hex,
  )

  return coupledParts && coupledParts?.length ? coupledParts : []
}

export const getColorMatchedPart = (
  device: any,
  section: string,
  frames: {
    parts: any[]
    default: any
  },
  devices: {
    parts: any[]
    default: any
  },
  activeColor: any,
  activeCustomerSet: any,
) => {
  if (section === 'frames') {
    const size = getPartSize(device.product)

    const possibleFramePool = frames.parts.filter((frame) => {
      const possibleSize = getPartSize(frame)

      return possibleSize === size
    })

    return possibleFramePool.find((part) => {
      const color = part.Properties.find((property) => property.Color).Color

      return color.id === activeColor.id
    })
  }

  if (section === 'decorativeFrames') {
    const currentDeviceColor = device.product.Properties.find(
      (property) => property.Color,
    ).Color

    if (currentDeviceColor.Name !== activeColor.Name) {
      removeFromCartCompletely({
        productId: device.product.id,
        propName: section,
        coupledItems: [],
        customerSetId: activeCustomerSet.id,
      })
    }

    return null
  }

  if (section === 'devices') {
    const subTypeDeviceInCartId = device.product?.SubType?.id || 0

    const possibleDevicePool = devices.parts.filter(
      (part) => part.SubType.id === subTypeDeviceInCartId,
    )

    return possibleDevicePool.find((part) => {
      const color = part.Properties?.find((property) => property.Color)?.Color

      return color?.id === activeColor.id
    })
  }
}
